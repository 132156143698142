import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import type { SlotId } from './config';
import type { ReelSetType } from './gql/query';
import type Animation from './slotMachine/animations/animation';
import type Tween from './slotMachine/animations/tween';
import type { BgSkin } from './slotMachine/background/background';
import type { SlotMachineState, eventManager } from './slotMachine/config';
import type { IWinLine, Icon, LineSet } from './slotMachine/d';

export interface IBalance {
  amount: number;
  currency: string;
  metadata?: IMetadata;
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    PIXI: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    Howler: {
      _spriteBaseUrl: string;
    };
    remoteStorage: RemoteStorage;
  }
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: IBalance;
}

export interface IMetadata {
  type: string;
  data: {
    messageTitle: string;
    messageBody: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface ISettledBet {
  balance: {
    placed: IBalance;
    settled: IBalance;
  };
  bet: {
    coinAmount: number;
    coinValue: number;
    data: {
      bonuses: UserBonus[];
    };
    id: string;
    lineSet: LineSet;
    reelSet: ReelSet;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonus: {
      betId: string;
    };
    reelSetId: string;
  };
  paylines: IWinLine[];
  rewards: BetReward[];
}
export type BetRewardType = 'BetCoinReward' | 'BetBonusReward' | 'ReplayBonusReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BonusType = 'SPECIAL_ROUND' | 'PRE_LOADED' | 'FREE_SPIN' | 'GAME_MODE' | 'FREE_BET_ROUND' | '';
export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type UserBonus = {
  betId: string;
  bonus: Bonus;
  coinValue: number;
  data: UserBonusData;
  bonusId: string;
  id: string;
  rounds: number;
  status: BonusStatus;
  totalWinAmount: number;
  // TODO remove it
  isActive: boolean;
  currentRound: number;
  totalRounds: number;
  reelSetId: string;
  coinAmount: number;
  isFreeBet?: boolean;
};

export type FreeRoundBonus = {
  id: string;
  bonusId: string;
  status: BonusStatus;
  coinAmount: number;
  coinValue: number;
  rounds: number;
  totalWinAmount: number;
  isActive: boolean;
  currentRound: number;
};

export type Bonus = {
  coinAmount: number;
  id: string;
  reelSetId: string;
  type: BonusType;
  // TODO remove it
  slotId: string;
  title?: string;
  description?: string;
  lineSetId: string | null;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};

export type UserBonusData = {
  battleBonusRounds: RoundType[];
  count: number;
  creditMultiplier: number;
  preLoadedGrantBreakdown: PreLoadedGrantBreakdown[];
  frbReferenceId: string | null;
};

export enum FeatureState {
  BB,
  HBB,
}

export enum BattleRoundMode {
  mode_1 = 'mode_1',
  mode_2 = 'mode_2',
  mode_3 = 'mode_3',
  mode_4 = 'mode_4',
  mode_5 = 'mode_5',
}
export enum BattleRoundScenario {
  scenario_1 = 'scenario_1',
  scenario_2 = 'scenario_2',
  scenario_3 = 'scenario_3',
  scenario_4 = 'scenario_4',
  scenario_5 = 'scenario_5',
  scenario_6 = 'scenario_6',
  scenario_7 = 'scenario_7',
  scenario_8 = 'scenario_8',
  scenario_9 = 'scenario_9',
}
export type RoundType = Round12345 | Round6;
export type Round12345 = {
  points: number;
  coinReward: number;
  mode: BattleRoundMode;
  round: number;
};

export type Round6 = {
  mode: BattleRoundMode;
  scenario: BattleRoundScenario;
  coinReward: number;
  round: number;
};
export type BattleBonusRounds = {
  round: RoundType;
};

export enum GameMode {
  REGULAR,
  RESPIN_1,
  RESPIN_2,
  RESPIN_3,
  RESPIN_4,
  BATTLE_BONUS,
  HYPER_BATTLE_BONUS,
  BUY_FEATURE_BATTLE_BONUS,
  BUY_FEATURE_HYPER_BATTLE_BONUS,
}
export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '4a544684-0564-4377-9ea2-4ef55b1e71b1',
  [GameMode.RESPIN_1]: 'bb9ec773-0a49-48cb-a6f8-445bc50a6cd2',
  [GameMode.RESPIN_2]: '1e0f5a29-b441-420b-a8ed-ca4194f8232c',
  [GameMode.RESPIN_3]: '7276fcb6-ee22-4564-944a-18c53e0723e9',
  [GameMode.RESPIN_4]: '846abe7a-ec9c-422b-97b8-64b8546a6b03',
  [GameMode.BATTLE_BONUS]: '5e6a25a9-72d1-4b75-b8de-1721e4fff985',
  [GameMode.HYPER_BATTLE_BONUS]: 'c2c96140-f11c-45b5-8e36-821c83b309bc',
  [GameMode.BUY_FEATURE_BATTLE_BONUS]: 'a0d72365-e27f-4f23-b316-e0c0cb294126',
  [GameMode.BUY_FEATURE_HYPER_BATTLE_BONUS]: '26fc3fa6-64d9-45ca-bb57-7382052cdaa8',
};

export const bonusIds: Record<GameMode, string> = {
  [GameMode.RESPIN_1]: 'f1ef4dea-7681-4d7d-9174-c9a02d9db235',
  [GameMode.RESPIN_2]: 'f208a998-97de-4cb0-b171-3c964077d02a',
  [GameMode.RESPIN_3]: 'f38fc9ab-c98c-4420-8bdb-f1d45ad49a1b',
  [GameMode.RESPIN_4]: 'f4dee296-bdd4-4b33-a061-aca9c4177c85',
  [GameMode.BATTLE_BONUS]: 'c5ce0df3-3925-4a76-aa49-de416da544d9',
  [GameMode.HYPER_BATTLE_BONUS]: '5b579a8e-3034-4911-ac85-4ab13f621bab',
  [GameMode.BUY_FEATURE_BATTLE_BONUS]: 'f6a8c34e-ed9f-4255-b88f-7b060e78f4d1',
  [GameMode.BUY_FEATURE_HYPER_BATTLE_BONUS]: '453f10cd-61b2-4d7f-8133-4fdd2a8f1125',
};

export const freeRoundBonusId = '66c93ebb-9bbd-4c8e-9195-69fa976b31ba';

export const lineSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: 'c3c62643-7291-4250-87a3-de99a537916d',
  [GameMode.RESPIN_1]: 'c3c62643-7291-4250-87a3-de99a537916d',
  [GameMode.RESPIN_2]: 'c3c62643-7291-4250-87a3-de99a537916d',
  [GameMode.RESPIN_3]: 'c3c62643-7291-4250-87a3-de99a537916d',
  [GameMode.RESPIN_4]: 'c3c62643-7291-4250-87a3-de99a537916d',
  [GameMode.BATTLE_BONUS]: 'c3c62643-7291-4250-87a3-de99a537916d',
  [GameMode.HYPER_BATTLE_BONUS]: 'c3c62643-7291-4250-87a3-de99a537916d',
  [GameMode.BUY_FEATURE_BATTLE_BONUS]: 'c3c62643-7291-4250-87a3-de99a537916d',
  [GameMode.BUY_FEATURE_HYPER_BATTLE_BONUS]: 'c3c62643-7291-4250-87a3-de99a537916d',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
}

export type BattleBnsTitleProps = {
  text: string;
  currentSet: number;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: () => void;
  onInitCallback?: () => void;
};

export type MessageBattleBonusBannerProps = BaseMessageBannerProps & {
  title: string;
  description: string;
  btnText: string;
  vsText: string;
  youText: string;
  rivalText: string;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
  bonusStreak: string;
  battleText: string;
  battleNum: string;
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_BATTLE_BNS_TITLE = 'createBattleBnsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_ALL_WIN_LINES = 'hideAllWinLines',
  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  JINGLE_START = 'jingleStart',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_LINES = 'hideWinLines',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  RESPIN = 'reSpin',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_BATTLE_BNS_TITLE = 'removeBattleBnsTitle',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_SLOTS_STOP_DISPLAY_VISIBILITY = 'setSlotsStopDisplayVisibility',
  SET_WIN_LABEL_TEXT = 'setWinLabelText',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_RETRIGGER_ANIMATION = 'startRetrigger',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_ENDING_FADE = 'startEndingFade',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_WIN_LABEL = 'showWinLabel',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  REMOVE_ANTICIPATION_TINT = 'removeAnticipationTint',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  HANDLE_UPDATE_BATTLE_BNS_TITLE = 'handleUpdateBattleBnsTitle',
  HANDLE_UPDATE_BATTLE_BNS_GET = 'handleUpdateBattleBnsGet',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREE_SPINS_WIN = 'isFreeSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_BATTLE_START_ANIM = 'isBattleStartAnim',
  SET_IS_IN_TRANSITION = 'isInTransition',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  HANDLE_SKIP_RESPIN_MESSAGE = 'handleSkipRespinMessage',
  RESPIN_START = 'RespinStart',
  CREATE_RESPIN_MESSAGE = 'createRespinMessage',
  NEARMISS_RESPIN_START = 'nearmissRespinStart',
  NEARMISS_RESPIN_END = 'nearmissRespinEnd',
  NEARMISS = 'nearmiss',
  NEARMISS_SKIP = 'nearmissSkip',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_UPDATE_BTN = 'openBuyFeatureUpdateBtn',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  SET_STATE = 'setState',
  BATTLE_BONUS_WIN_AMOUNT = 'battleBonusWinAmount',
  BATTLE_BONUS_SET_ICON = 'battleBonusSetIcon',
  BATTLE_BONUS_ROUND1 = 'battleBonusRound1',
  SLOT_STOP_DISPLAY_HIDE = 'SlotStopDisplayHide',
  BATTLE_BONUS_BACK_START = 'BattleBonusBackStart',
  BATTLE_BONUS_BACK_END = 'BattleBonusBackEnd',
  BATTLE_BONUS_SP_BACK_START = 'BattleBonusSpBackStart',
  BATTLE_BONUS_SP_BACK_END = 'BattleBonusSpBackEnd',
  BATTLE_BONUS_BACK_R6_START = 'BattleBonusBackR6Start',
  BATTLE_BONUS_BACK_R6_END = 'BattleBonusBackR6End',
  BATTLE_BONUS_INTRO_START = 'BattleBonusIntroStart',
  BATTLE_BONUS_R6_INTRO_START = 'BattleBonusR6IntroStart',
  BATTLE_BONUS_RESULT = 'BattleBonusResult',
  BATTLE_BONUS_RESULT_END = 'BattleBonusResultEnd',
  TRIGGER_JINGLE = 'TriggerJingle',
  CHARA_WINDOW_ANIMATION = 'CharaWindowAnimation',
  BATTLE_BONUS_COUNT_UP_START = 'BattleBonusCountUpStart',
  BATTLE_BONUS_ANIM_SKIP = 'BattleBonusAnimSkip',
  ARMS_RE_CHANGE = 'ArmsReChange',
  BATTLE_START_ANIM_START = 'BattleStartAnimStart',
  BATTLE_START_ANIM_LOOP_START = 'BattleStartAnimLoopStart',
  BATTLE_BNS_START_TOGGLE_SPIN = 'BattleBnsStartToggleSpin',
  PLACE_BET_COMPLETED = 'PlaceBetCompleted',
  WIN_TITLE_DISABLE = 'WinTitleDisable',
  SET_ENDING_DISPLAY = 'SetEndingDisplay',
  SET_IS_RESPIN_BUSY = 'SetIsRespinBusy',
  SET_SLOT_BUSY_DISABLE = 'SetSlotBusyDisable',
  BATTLE_BONUS_ANIM_START = 'BattleBonusAnimStart',
  PHOENIX_START = 'PhoenixStart',
  BATTLE_BONUS_ANIM_END = 'BattleBonsAnimEnd',
  UI_VISIBLE = 'UiVisible',
  BATTLE_BNS_END = 'BattleBnsEnd',
  WINDOW_NOT_ACTIVE = 'WindowNotActive',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  CHANGE_STATE = 'changeState',
  SET_IS_FREE_ROUND_BONUS = 'setIsFreeRoundBonus',
  SET_IS_POPUP_FREE_ROUNDS_OPENED = 'isOpenPopupFreeRounds',
  SET_IS_GET_USER_BONUSES = 'setIsGetUserBonuses',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE = 'updateFreeRoundBonusTotalWinValue',
  OPEN_POPUP_FREE_ROUNDS = 'openPopupFreeRounds',
  OPEN_POPUP_FREE_ROUNDS_END = 'openPopupFreeRoundsEnd',
  START_FADE_TRANSITION_FREE_ROUNDS_BONUS = 'startFadeTransitionFreeRoundBonus',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoPlay',
  START_FREE_SPINS = 'startFreeSpins',
  END_FREE_SPINS = 'endFreeSpins',
  END_RE_SPINS = 'endReSpins',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: () => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: () => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    background?: BgSkin;
    isRetrigger?: boolean;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_BATTLE_BNS_TITLE]: (props: BattleBnsTitleProps) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageFreeSpinsBannerProps) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.JINGLE_START]: () => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HIDE_WIN_LINES]: (line: IWinLine[]) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.RESPIN]: () => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_BATTLE_BNS_TITLE]: () => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_SLOTS_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.SET_WIN_LABEL_TEXT]: () => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (nextResult: ISettledBet, isTurboSpin: boolean) => void;
  [EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_RETRIGGER_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    background?: BgSkin;
  }) => void;
  [EventTypes.START_ENDING_FADE]: (reelPositions: number[], reelSetId: string) => void;
  [EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.HIDE_ALL_WIN_LINES]: () => void;
  [EventTypes.SHOW_WIN_LABEL]: () => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], constructorFlg: boolean, reelId?: number) => void;
  [EventTypes.SHOW_TINT]: (state: boolean, reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: Array<number>,
    scatterNo: Array<number>,
    anticipationStartReelId: number,
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.REMOVE_ANTICIPATION_TINT]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.HANDLE_UPDATE_BATTLE_BNS_TITLE]: (currentSet: number, immediately: boolean) => void;
  [EventTypes.HANDLE_UPDATE_BATTLE_BNS_GET]: (currentGet: number, immediately: boolean) => void;
  [EventTypes.SET_IS_LEFT_HAND_MODE]: (isLeftHandMode: boolean) => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREE_SPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_BATTLE_START_ANIM]: (isBattleStartAnim: boolean) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: (isError: boolean) => void;
  [EventTypes.ENABLE_SOUND_LOADER]: () => void;
  [EventTypes.HANDLE_SKIP_RESPIN_MESSAGE]: () => void;
  [EventTypes.CREATE_RESPIN_MESSAGE]: () => void;
  [EventTypes.NEARMISS_RESPIN_START]: () => void;
  [EventTypes.NEARMISS_RESPIN_END]: () => void;
  [EventTypes.RESPIN_START]: () => void;
  [EventTypes.NEARMISS]: () => void;
  [EventTypes.NEARMISS_SKIP]: () => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusId: string) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_UPDATE_BTN]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (isBattleBonus: boolean, totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: () => void;
  [EventTypes.SET_STATE]: (state: SlotMachineState) => void;
  [EventTypes.BATTLE_BONUS_WIN_AMOUNT]: (result: ISettledBet, battleBonusRounds: RoundType) => void;
  [EventTypes.BATTLE_BONUS_SET_ICON]: (round: number, icon: ResourceTypes) => void;
  [EventTypes.BATTLE_BONUS_ROUND1]: () => void;
  [EventTypes.SLOT_STOP_DISPLAY_HIDE]: (slots: number) => void;
  [EventTypes.BATTLE_BONUS_BACK_START]: (isHero: boolean) => void;
  [EventTypes.BATTLE_BONUS_BACK_END]: () => void;
  [EventTypes.BATTLE_BONUS_SP_BACK_START]: () => void;
  [EventTypes.BATTLE_BONUS_SP_BACK_END]: () => void;
  [EventTypes.BATTLE_BONUS_BACK_R6_START]: (anim: string) => void;
  [EventTypes.BATTLE_BONUS_BACK_R6_END]: () => void;
  [EventTypes.BATTLE_BONUS_INTRO_START]: () => void;
  [EventTypes.BATTLE_BONUS_R6_INTRO_START]: () => void;
  [EventTypes.BATTLE_BONUS_RESULT]: (point: number) => void;
  [EventTypes.BATTLE_BONUS_RESULT_END]: (scenario: BattleRoundScenario) => void;
  [EventTypes.TRIGGER_JINGLE]: () => void;
  [EventTypes.CHARA_WINDOW_ANIMATION]: (animation: AnimationType, sound: RntISongs) => void;
  [EventTypes.BATTLE_BONUS_COUNT_UP_START]: () => void;
  [EventTypes.BATTLE_BONUS_ANIM_SKIP]: () => void;
  [EventTypes.ARMS_RE_CHANGE]: () => void;
  [EventTypes.BATTLE_START_ANIM_START]: (battleNum: number) => void;
  [EventTypes.BATTLE_START_ANIM_LOOP_START]: () => void;
  [EventTypes.BATTLE_BNS_START_TOGGLE_SPIN]: () => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.WIN_TITLE_DISABLE]: () => void;
  [EventTypes.SET_ENDING_DISPLAY]: (reelPositions: number[], reelSetId: string) => void;
  [EventTypes.SET_IS_RESPIN_BUSY]: (isRespinBusy: boolean) => void;
  [EventTypes.SET_SLOT_BUSY_DISABLE]: () => void;
  [EventTypes.BATTLE_BONUS_ANIM_START]: () => void;
  [EventTypes.PHOENIX_START]: () => void;
  [EventTypes.BATTLE_BONUS_ANIM_END]: () => void;
  [EventTypes.UI_VISIBLE]: () => void;
  [EventTypes.BATTLE_BNS_END]: () => void;
  [EventTypes.WINDOW_NOT_ACTIVE]: () => void;
  [EventTypes.FORCE_CLOSE_BUYFEATURE]: () => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;
  [EventTypes.CHANGE_STATE]: () => void;
  [EventTypes.SET_IS_FREE_ROUND_BONUS]: (isFreeRoundBonus: boolean) => void;
  [EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED]: (isPopupFreeRoundsOpened: boolean) => void;
  [EventTypes.SET_IS_GET_USER_BONUSES]: (isGetUserBonuses: boolean) => void;
  [EventTypes.UPDATE_FREE_ROUNDS_LEFT]: (rounds: number) => void;
  [EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS]: (rounds: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS_END]: (isExpired?: boolean) => void;
  [EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS]: (settings: {
    outDuration: number;
    inDuration: number;
    callback: () => void;
  }) => void;
  [EventTypes.FORCE_STOP_AUTOPLAY]: () => void;
  [EventTypes.START_FREE_SPINS]: () => void;
  [EventTypes.END_FREE_SPINS]: () => void;
  [EventTypes.END_RE_SPINS]: (activeBonuses: UserBonus[]) => void;
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}

export enum BgmSoundTypes {
  BASE = 'regular',
  RP1 = 'rp1',
  RP2 = 'rp2',
  RP3 = 'rp3',
  RP4 = 'rp4',
  BB1 = 'bb1',
  BB2 = 'bb2',
}

export enum ReelAnticipation {
  NON = 'NON',
  RESPIN = 'RESPIN',
  BONUS = 'BONUS',
}

export enum BattleBonusUpperColor {
  RED = 'RED',
  PURPLE = 'PURPLE',
  RAINBOW = 'RAINBOW',
}
